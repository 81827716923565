import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Hcp = () => {
  return (
    <>
      <div className="change-culture1 ptb-80 bg-f7fafd">
        <div className="container">
          <div className="row align-items-center pt-80">
            <div className="col-lg-6 col-md-12">
              <div className="about-image" style={{ paddingRight: "10px" }}>
                <StaticImage
                  src="../../assets/images/hcp.png"
                  alt="Why join Atyeti?"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="change-culture-content">
                <h3>Atyeti Core Services … Built with HashiCorp</h3>
                <div className="section-title">
                  <div className="bar"></div>
                  <ul>
                    <li>
                      <strong>Foundation & Prepare</strong> : Implementation of
                      the core tooling and controls required for hybrid cloud
                      environments.
                    </li>
                    <li>
                    <strong>Build & Automate</strong> :
                      Building reusable automations that
                      leverage the Foundations established in Day 0 into
                      end-to-end solutions. 
                       {/* Automations enable streamlined and
                      efficient provisioning, configuration, and management of
                      hybrid cloud resources. */}
                    </li>
                    <li>
                    <strong>Optimize & Evolve </strong>:
                     Leverage the benefits of
                      business-oriented architecture to embrace change, enable
                      zero-trust.
                       {/* enhance developer experience (DevX), drive
                      factory-style migrations, and facilitate seamless hybrid
                      cloud adoption. */}
                    </li>
                    <li>
                      <strong>Common Challenges</strong> : Legacy systems face
                      several challenges including: slow release cycles, reduced
                      collaboration, limited automation, decreased agility,
                      increased downtime, scalability issues, security concerns,
                      unknown technical debt, reduced competitive edge, and
                      higher cost.
                    </li>
                    <li>
                      <strong>Common Goals</strong> : Application Modernization
                      aims to enhance performance, agility, security, user
                      experience, and day-to-day operations while reducing cost,
                      technical debt, and time-to-market enabling teams to
                      realize the value of hybrid cloud and providing a
                      framework for the future.
                    </li>
                  </ul>
                  {/* <img src={dayinlife} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hcp
